<template>
  <div class="row mx-0 h-100">
    <div class="col-12 p-0 card_item h-100 cursor" @click="goToNews">
      <div class="row mx-0 h-100 justify-content-center align-items-start">
        <div class="col-12 p-0">
          <div class="row mx-0 h-100 justify-content-center align-items-start">
            <div class="col-12 p-0" v-if="newsItem.imageUrl" ref="theImage">
              <Image :imageName="newsItem.imageUrl" :width="theImageWidth" :height="theImageHeight" :alt="newsItem.name" theClass="img_fill" />
              <!-- <img :src="url + '/static/uploads/images/' + newsItem.imageUrl" :alt="newsItem.name" class="img_fill" /> -->
            </div>
            <div class="col-12 px-4 pt-4 align-self-start">
              <div class="row mx-0 justify-content-center">
                <div class="col-12 text-center p-0 card_title" v-if="newsItem.title">
                  {{newsItem.title}}
                </div>
                <div class="col-12 text-center p-0 my-3 card_date" v-if="newsItem.lastModifiedDate">
                  {{getDate}}
                </div>
                <div class="col-12 text-center p-0 news_summary" v-if="newsItem.summary">
                  {{newsItem.summary}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 p-4 align-self-end">
          <div class="row mx-0 justify-content-center">
            <div class="col-12 p-0 mt-3 view text-center">
              <span class="cursor" @click="goToNews">View Article</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { defineAsyncComponent } from 'vue'
export default {
  name: 'News Card',
  props: ['newsItem'],
  components: {
    Image: defineAsyncComponent(() => import('@/components/Image.vue'))
  },
  data () {
    return {
      url: process.env.VUE_APP_URL_BASE,
      theImageWidth: 0,
      theImageHeight: 0
    }
  },
  async mounted () {
    await this.getDimensions()
  },
  computed: {
    getDate () {
      const date = DateTime.fromMillis(this.newsItem.lastModifiedDate)
      return date.toLocaleString(DateTime.DATE_FULL)
    }
  },
  methods: {
    getDimensions () {
      this.theImageWidth = this.$refs.theImage.clientWidth
      this.theImageHeight = this.$refs.theImage.clientHeight
    },
    goToNews () {
      window.scroll(0, 0)
      this.$router.push('/news/' + this.newsItem.id)
    }
  }
}
</script>

<style scoped>
.card_item {
  background-color: #f4f4f4;
  border-radius: 13px;
  box-shadow: rgba(0, 0, 0, 0.09) 5px 5px 10px 0px;
}
.card_title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--green-color);
}
.card_date {
  font-family: "quicksand_bold", Sans-serif;
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--gold-color);
}
.img_fill {
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  object-fit: cover;
  width: 100%;
  max-height: 250px;
  opacity: 1;
  z-index: 1;
}
.view {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 0.7rem;
  font-weight: 600;
  color: var(--gold-color);
  text-transform: uppercase;
}
.news_summary {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
@media (min-width: 992px) {
  .card_title {
    font-size: 1.5rem;
  }
}
</style>
